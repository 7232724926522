import Place from '@components/fields/Place'
import {Field, Form} from 'simple-react-form'

import styles from './styles.module.css'

export default function Top({title, preferences, updateAddress}) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>{title ? title : '¡Busca tu local más cercano!'}</h1>
      </div>
      <p className={styles.description}>
        Ingresa tu dirección para mostrarte los locales más cercanos
      </p>
      <Form state={preferences} onChange={updateAddress} useFormTag={false}>
        <Field
          fieldName="placeId"
          passProps={{placeholderText: 'Dirección de entrega*'}}
          type={Place}
          openCustom={true}
          setOpenCustom={true}
        />
      </Form>
    </div>
  )
}
