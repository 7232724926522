import withClientSuspense from '@helpers/misc/withClientSuspense'
import useWidth from '@hooks/useWidth'
import Container from '@packages/justo-parts/lib/components/Container'
import classnames from 'classnames'

import Store from './Store'
import StoreMap from './StoreMap'
import Top from './Top'
import useStoreLocator from './useStoreLocator'

import styles from './styles.module.css'

function StoreLocator({title, icon, color}) {
  const {stores, map, selectedStore, setSelectedStore, preferences, updateAddress, homeLocation} =
    useStoreLocator()

  const width = useWidth()
  const isMobile = width < 768

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.sectionContainer}>
            {isMobile ? (
              <Top title={title} preferences={preferences} updateAddress={updateAddress} />
            ) : null}
            <StoreMap
              map={map}
              stores={stores}
              iconURL={icon}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              color={color}
              homeLocation={homeLocation}
            />
          </div>
          <div className={classnames(styles.sectionContainer, isMobile ? null : styles.overflow)}>
            {isMobile ? null : (
              <Top title={title} preferences={preferences} updateAddress={updateAddress} />
            )}
            <div className={styles.stores}>
              {stores.map((store, idx) => (
                <Store key={idx} store={store} onClick={() => setSelectedStore(store)} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default withClientSuspense(StoreLocator)
