import getDistanceFromLatLonInKm from '@helpers/misc/coordinatesDistance'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery, useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'
import {useMemo, useRef, useState} from 'react'

const GET_STORES = gql`
  query getStoresZones($websiteId: ID) {
    stores(websiteId: $websiteId) {
      items {
        _id
        name
        phone
        supportOptions {
          phone
        }
        humanSchedule {
          days
          schedule
        }
        acceptDelivery
        acceptGo
        zones {
          _id
          deliveryLimits
        }
        address {
          placeId
          location
          address
          addressSecondary
        }
      }
    }
  }
`

const GET_USER_PREFERENCES = gql`
  query getUserPreferencesStoreLocator($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      websiteId
      placeId
      address {
        _id
        placeId
        address
        addressSecondary
        addressLine2
        acceptsNoLine2
        location
        comment
      }
    }
  }
`

const SET_PLACE_ID = gql`
  mutation setPlaceIdStoreLocator($websiteId: ID, $placeId: ID) {
    setUserPreferences(websiteId: $websiteId, placeId: $placeId) {
      _id
      websiteId
      placeId
      address {
        _id
        placeId
        address
        addressSecondary
        addressLine2
        acceptsNoLine2
        location
        comment
      }
    }
  }
`

export default function useStoreLocator() {
  const websiteId = useWebsiteId()
  const map = useRef()
  const [selectedStore, setSelectedStore] = useState()
  const mutate = useMutate()
  const showMessage = useMessage()

  const {stores: allStores} = useApolloQuery({query: GET_STORES, variables: {websiteId}})

  const {preferences} = useApolloQuery({
    query: GET_USER_PREFERENCES,
    variables: {websiteId},
  })

  const homeLocation = get(preferences, 'address.location', null)

  const stores = useMemo(() => {
    let stores = allStores
      ? allStores.items.filter(
          store => store.acceptDelivery && store.address && store.address.location,
        )
      : []

    if (!homeLocation) {
      return stores
    }

    const {lat, lng} = homeLocation

    stores = stores.map(store => {
      const {lat: storeLat, lng: storeLng} = get(store, 'address.location', {})

      return {
        ...store,
        distanceToAddress:
          storeLat && storeLng ? getDistanceFromLatLonInKm(lat, lng, storeLat, storeLng) : 1000,
      }
    })

    return stores.sort((store1, store2) => store1.distanceToAddress - store2.distanceToAddress)
  }, [allStores, homeLocation])

  const setSelectedStoreWrapper = store => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    map.current?.panTo(store.address.location)
    setSelectedStore(store)
  }

  const updateAddress = async ({placeId}) => {
    try {
      const {setUserPreferences} = await mutate({
        mutation: SET_PLACE_ID,
        variables: {websiteId, placeId},
        refetchQueries: ['getUserPreferences'],
      })
      const newLocation = get(setUserPreferences, 'address.location')
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      newLocation && map.current && map.current.panTo(newLocation)
      showMessage('Dirección modificada con éxito')
    } catch (error) {
      showMessage(error)
    }
  }

  return {
    stores,
    map,
    selectedStore,
    setSelectedStore: setSelectedStoreWrapper,
    updateAddress,
    preferences,
    homeLocation,
  }
}
