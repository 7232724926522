// source: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula

/**
 * @returns Distance in km.
 */
export default function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  try {
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return Math.round(d * 10) / 10
  } catch (err) {
    // give a high value has fallback to prevent this store from appearing at the top
    return 1000
  }
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
