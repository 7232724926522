import React, {useState} from 'react'
import {FaPhone} from 'react-icons/fa'
import classnames from 'classnames'

import styles from './styles.module.css'

export default function Store({store, onClick}) {
  const [open, setOpen] = useState(false)

  const wrappedOnClick = () => {
    !open && onClick()
    setOpen(!open)
  }

  return (
    <div className={classnames(styles.container, open && styles.close)}>
      <div className={classnames(styles.header, open && styles.headerMargin)}>
        <div className={styles.headerTitle} onClick={wrappedOnClick}>
          <h3>{store.name}</h3>
          {store.distanceToAddress ? <span>{store.distanceToAddress}km</span> : null}
        </div>
        <div className={classnames(styles.arrow, !open && styles.invert)} onClick={wrappedOnClick}>
          <img src="/images/icons/open_arrow.svg" alt="ocultar" />
        </div>
      </div>
      <div>
        <div className={styles.row}>
          <img
            className={styles.icon}
            src="/images/icons/address.svg"
            alt="dirección"
            width={16}
            height={16}
          />
          <a
            className={styles.link}
            href={
              store.address.location
                ? `https://maps.google.com?q=${store.address.location.lat},${store.address.location.lng}`
                : '#'
            }
            target="_blank"
            rel="noreferrer">
            {store.address.address}, {store.address.addressSecondary}
          </a>
        </div>
      </div>
      <div className={open ? styles.body : styles.hide}>
        {store.supportOptions && store.supportOptions.phone ? (
          <div className={styles.row}>
            <FaPhone size={16} className={styles.icon} aria-label="teléfono" />
            <a className={styles.link} href={`tel:${store.supportOptions.phone}`}>
              {store.supportOptions.phone}
            </a>
          </div>
        ) : null}
        <div className={styles.row}>
          <img
            className={styles.icon}
            src="/images/icons/watch.svg"
            alt="programación"
            width={16}
            height={16}
          />
          <span>
            {store.humanSchedule ? (
              <span>
                {store.humanSchedule
                  .map(schedule => `${schedule.days}, ${schedule.schedule}`)
                  .join('. ')}
              </span>
            ) : null}
          </span>
        </div>
        <div className={styles.tags}>
          {store.acceptDelivery ? (
            <div className={styles.box}>
              <span className={styles.marginRight}>Delivery disponible</span>
              <img src="/images/icons/delivery.svg" alt="delivery" width={16} height={16} />
            </div>
          ) : null}
          {store.acceptGo ? (
            <div className={classnames(styles.box, styles.margin)}>
              <span className={styles.marginRight}>Retiro disponible</span>
              <img src="/images/icons/store.svg" alt="retira en tienda" width={16} height={16} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
